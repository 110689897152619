import {styled} from '@mui/system';
import CallMadeIcon from '@mui/icons-material/CallMade';
import {Button, Typography} from '@mui/material';
const CustomButton = styled(Button)({
  maxWidth: '160px', // Largura máxima do botão
  backgroundColor: '#fff', // Cor de fundo inicial
  color: '#000', // Cor do texto

  // Efeito hover
  '&:hover': {
    backgroundColor: '#808080', // Cor de fundo ao passar o mouse
    color: '#000', // Cor do texto ao passar o mouse
  },
});

const CustomButtonContact = () => {
 

  return (
    <CustomButton variant='contained'>
      <Typography component='a' href='#contact' sx={{color: '#000', textDecoration: 'none', fontSize: '14px', display: 'flex', alignItems: 'center'}}>
        Contact us <CallMadeIcon sx={{ml: 1, fontSize: 18}} />
      </Typography>
    </CustomButton>
  );
};

export default CustomButtonContact;

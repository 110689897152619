import image1 from '../assets/carousel-plank/Rectangle1.png';
import image2 from '../assets/carousel-plank/Rectangle2.png';
import image3 from '../assets/carousel-plank/Rectangle3.png';
import image4 from '../assets/carousel-plank/Rectangle4.png';

export const dataCarouselPlank = [
  {
    title: 'Karndean Korlok Select Texas White Ash Luxury Vinyl Plank',
    img: image1, // Substitua pelo link da imagem ou importe localmente
  },
  {
    title: 'Mohawk RevWood Select Rare Vintage CDL74 Laminate Plank',
    img: image2, // Substitua pelo link da imagem ou importe localmente
  },
  {
    title:
      'Carvalho Hanover Hardwood Flooring: Timeless Elegance for Every Space',
    img: image3, // Substitua pelo link da imagem ou importe localmente
  },
  {
    title: 'Durable and Stylish Laminate Flooring for Modern Living',
    img: image4, // Substitua pelo link da imagem ou importe localmente
  },
];

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  Menu,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {useEffect, useState} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {Link} from 'react-router-dom'; // Importando o Link do react-router-dom
import CustomButtonContact from './CustomButtonContact';
import {motion, useScroll} from 'framer-motion';

const Navbar = () => {
  // Estado para controlar o menu lateral
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Estado para controlar o submenu de "Book Online"
  const [submenuOpen, setSubmenuOpen] = useState<null | HTMLElement>(null);

  const [position, setPosition] = useState<'fixed' | 'relative'>('relative'); // Inicialize com um valor padrão, como 'relative'
  const [colorBg, setColorBg] = useState<'#000' | '#808080'>('#000'); // Inicialize com um valor padrão, como 'relative'
  const {scrollYProgress} = useScroll(); // Pegando o valor do scroll

  useEffect(() => {
    const unsubscribe = scrollYProgress.on('change', () => {
      if (scrollYProgress.get() > 0.003) {
        setColorBg('#808080'); // Altera a cor de fundo para '#808080' quando scrollYProgress > 0.1
        setPosition('fixed');
      } else {
        setPosition('relative'); // Retorna ao estado normal quando abaixo de 0.5
        setColorBg('#000'); // Retorna ao estado normal quando abaixo de 0.5
      }
    });

    // Cleanup da função para evitar vazamento de memória
    return () => unsubscribe();
  }, [scrollYProgress]);

  // Itens do menu e submenu
  const menuItems = [
    {label: 'Home', path: '/'},
    {label: 'Our Works', path: '/works'},
    {label: 'Gallery', path: '/gallery'},
  ];
  // Função para alternar o drawer
  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  // Função para abrir/fechar submenu de "Book Online" na versão desktop
  const handleSubmenuOpen = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    setSubmenuOpen(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuOpen(null);
  };

  return (
    <motion.div
      style={{
        position,
        width: '100%',
        top: 0,
        background: colorBg,
        transition: '.5s ease-in-out',
        zIndex: 10000,
      }}
      transition={{duration: 0.5}}
    >
      {/* AppBar com o toolbar */}
      <AppBar color='transparent' position='static' sx={{color: '#fff'}}>
        <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
          {/* Menu colapsado (pequenas telas) */}
          <IconButton
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={toggleDrawer(true)}
            sx={{display: {xs: 'block', md: 'none'}}}
          >
            <MenuIcon />
          </IconButton>

          {/* Links de navegação (para telas maiores) */}
          <Box sx={{display: {xs: 'none', md: 'flex'}, alignItems: 'center'}}>
            {menuItems.map((item) =>
              item.label === 'Book Online' ? (
                <div key={item.label}>
                  <Button
                    component={Link}
                    to={item.path}
                    color='inherit'
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                      event.preventDefault();
                      handleSubmenuOpen(event);
                    }}
                    endIcon={
                      submenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />
                    }
                  >
                    {item.label}
                  </Button>

                  <Menu
                    anchorEl={submenuOpen}
                    open={Boolean(submenuOpen)}
                    onClose={handleSubmenuClose}
                    MenuListProps={{
                      'aria-labelledby': 'submenu-button',
                    }}
                    sx={{zIndex: 100000000}}
                  ></Menu>
                </div>
              ) : (
                <Button
                  key={item.label}
                  color='inherit'
                  component={Link}
                  to={item.path}
                >
                  {item.label}
                </Button>
              )
            )}
          </Box>
          <CustomButtonContact />
        </Toolbar>
      </AppBar>

      {/* Drawer lateral para telas pequenas */}
      <Drawer
        anchor='left'
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{zIndex: 1000000}}
      >
        <Box
          sx={{width: 250}}
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {menuItems.map((item) => (
              <ListItem key={item.label} component={Link} to={item.path}>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </motion.div>
  );
};

export default Navbar;

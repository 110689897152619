import {Box, Typography, Container} from '@mui/material';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import BoxSectionContainer from '../BoxSectionContainer';

const PrivacyPolicy = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <BoxSectionContainer title='Privacy Policy'>
        <Container maxWidth='md' sx={{mt: 4, mb: 4}}>

          <Box mb={4}>
            <Typography variant='h6' gutterBottom>
              1. General Information
            </Typography>
            <Typography>
              This Privacy Policy outlines how we collect, use, and protect the
              personal data of visitors on our website (www.gbflooringllc.com),
              where we provide flooring installation services. We are committed
              to respecting and protecting your privacy and any personal
              information you provide.
            </Typography>
          </Box>

          <Box mb={4}>
            <Typography variant='h6' gutterBottom>
              2. Data Collected and Purpose of Use
            </Typography>

            <Box mb={2}>
              <Typography variant='subtitle1' gutterBottom>
                2.1 Personal Data
              </Typography>
              <Typography>
                We may collect personal data, such as name, email, phone number,
                and address, voluntarily provided by users when they contact us
                or request a quote. This information is used to:
              </Typography>
              <Typography component='ul'>
                <li>Respond to inquiries and requests;</li>
                <li>
                  Send information about our products, services, and promotions
                  (with user consent).
                </li>
              </Typography>
            </Box>

            <Box mb={2}>
              <Typography variant='subtitle1' gutterBottom>
                2.2 Navigation Data
              </Typography>
              <Typography>
                We collect information on users’ browsing activities through
                cookies and similar technologies. This data includes IP
                addresses, device type, visit time, and pages accessed. It is
                used to:
              </Typography>
              <Typography component='ul'>
                <li>Improve user experience;</li>
                <li>Analyze and enhance website performance.</li>
              </Typography>
            </Box>
          </Box>

          <Box mb={4}>
            <Typography variant='h6' gutterBottom>
              3. Data Sharing
            </Typography>
            <Typography>
              We do not sell, rent, or share users' personal data with third
              parties, except when required by law or necessary to fulfill
              contracted services, such as product shipping and installation
              services.
            </Typography>
          </Box>

          <Box mb={4}>
            <Typography variant='h6' gutterBottom>
              4. Data Storage and Security
            </Typography>
            <Typography>
              We implement appropriate security measures to protect users’
              personal information from unauthorized access, alteration,
              disclosure, or destruction. Data storage complies with applicable
              laws and retains data for the necessary period to fulfill this
              policy's objectives.
            </Typography>
          </Box>

          <Box mb={4}>
            <Typography variant='h6' gutterBottom>
              5. Data Subject Rights
            </Typography>
            <Typography>
              Users have the right to access, correct, update, or delete their
              personal data at any time. To exercise these rights, please
              contact us.
            </Typography>
          </Box>

          <Box mb={4}>
            <Typography variant='h6' gutterBottom>
              6. Use of Cookies
            </Typography>
            <Typography>
              Our website uses cookies to enhance navigation and functionality.
              Users may configure their browsers to decline cookies, but certain
              site functionalities may be limited.
            </Typography>
          </Box>

          <Box mb={4}>
            <Typography variant='h6' gutterBottom>
              7. Changes to this Privacy Policy
            </Typography>
            <Typography>
              We reserve the right to modify this Privacy Policy at any time by
              posting an updated version on our website. We recommend users
              review this page periodically to stay informed about our privacy
              practices.
            </Typography>
          </Box>

          <Box mb={4}>
            <Typography variant='h6' gutterBottom>
              8. Contact
            </Typography>
            <Typography>
              For questions regarding this Privacy Policy, please contact us at:
              <a href='mailto:contact@gbflooring.com.'>
                contact@gbflooring.com.
              </a>
            </Typography>
          </Box>
        </Container>
      </BoxSectionContainer>
    </>
  );
};

export default PrivacyPolicy;


import image1Carousel from '../assets/carousel-home/image1.png';
import image2Carousel from '../assets/carousel-home/image2.jpeg';
import image3Carousel from '../assets/carousel-home/image3.jpeg';
import image6Carousel from '../assets/carousel-home/image6.jpeg';


export const dataNewserviceHomeCarousel = [
  {
    name: 'Give your home a new environment, a new story, a new path. With the floor your home transforms.',
    description:
      'Discover the elegance and durability of our premium hardwood flooring. At Woodcove Flooring, we provide top-quality materials and expert craftsmanship to enhance your home or business.',
    imageUrl: image1Carousel,
  },
  {
    name: 'Give your home a new environment, a new story, a new path. With the floor your home transforms.',
    description:
      'Discover the elegance and durability of our premium hardwood flooring. At Woodcove Flooring, we provide top-quality materials and expert craftsmanship to enhance your home or business.',
    imageUrl: image2Carousel,
  },
  {
    name: 'Give your home a new environment, a new story, a new path. With the floor your home transforms.',
    description:
      'Discover the elegance and durability of our premium hardwood flooring. At Woodcove Flooring, we provide top-quality materials and expert craftsmanship to enhance your home or business.',
    imageUrl: image3Carousel,
  },
  {
    name: 'Give your home a new environment, a new story, a new path. With the floor your home transforms.',
    description:
      'Discover the elegance and durability of our premium hardwood flooring. At Woodcove Flooring, we provide top-quality materials and expert craftsmanship to enhance your home or business.',
    imageUrl: image6Carousel,
  },
];
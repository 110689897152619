
import { Box, Typography } from '@mui/material';

const BoxSectionContainer = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          component={'p'}
          variant='h5'
          sx={{
            p: 2,
            textAlign: 'center',
            fontWeight: 'bold',
            mt: 2,
            mb: 2,
            borderBottom: '3px solid #000',
            maxWidth: '600px',
          }}
        >
          {title}
        </Typography>
        {children}
      </Box>
    </>
  );
};

export default BoxSectionContainer;
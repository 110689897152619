import {Box, Paper, Modal} from '@mui/material';
import {useState} from 'react';
import BoxSectionContainer from '../BoxSectionContainer';
import CloseIcon from '@mui/icons-material/Close'; // Importando ícone de fechar

const Gallery = ({images}: {images: string[]}) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <BoxSectionContainer title='Gallery'>
      <Box sx={{p: 2}}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          {images.map((image, index) => (
            <Paper
              key={index}
              elevation={3}
              sx={{
                borderRadius: 2,
                overflow: 'hidden',
                width: {xs: '100%', sm: '48%', md: '30%'},
                m: 1,
                position: 'relative',
              }}
              onClick={() => handleImageClick(image)} // Adiciona o evento de clique
            >
              <Box
                component='img'
                src={image}
                alt={`Gallery Image ${index + 1}`}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  aspectRatio: '16/9',
                  cursor: 'pointer',
                }}
              />
            </Paper>
          ))}
        </Box>
      </Box>

      {/* Modal para exibir a imagem aumentada */}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 999999999,
        }}
      >
        <>
          <Box
            sx={{
              backgroundColor: 'rgb(0, 0, 0)',
              p: 1,
              borderRadius: '50%',
              position: 'fixed',
              top: 10,
              right: 10,
              zIndex: 9999999999999,
              display: 'flex',
            }}
          >
            <CloseIcon
              sx={{cursor: 'pointer', color: '#fff'}}
              onClick={handleClose}
            />
          </Box>
          <Box
            component='img'
            src={selectedImage || ''}
            alt='Selected Image'
            sx={{
              maxWidth: '90%', // Limita a largura máxima da imagem
              maxHeight: '90%', // Limita a altura máxima da imagem
              borderRadius: 2,
            }}
          />
        </>
      </Modal>
    </BoxSectionContainer>
  );
};

export default Gallery;

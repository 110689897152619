import {dataServicesNewWork} from '../../data/dataServicesNewWork';
import BoxSectionContainer from '../BoxSectionContainer';
import InfoCardItemNewService from '../InfoCardItemNewService';
import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

const OurWorks = () => {
  const location = useLocation();

  // Usamos uma ref de objeto para armazenar referências dinâmicas aos elementos
  const sectionsRef = useRef<Record<string, HTMLElement>>({});

  // Função para atribuir um callback ref a cada seção com um determinado ID
  const setSectionRef = (element: HTMLElement) => {
    if (element) {
      // Salva a referência do elemento pelo seu ID
      sectionsRef.current[element.id] = element;
    }
  };

  useEffect(() => {
    // Verifica se a URL possui um hash (#) e rola até o elemento correspondente na ref
    const hash = location.hash.replace('#', ''); // Remove o "#" do hash
    if (hash && sectionsRef.current[hash]) {
      const element = sectionsRef.current[hash];
      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    }
    console.log(sectionsRef.current);
  }, [location]);

  return (
    <>
      <BoxSectionContainer title='Our Works'>
        <InfoCardItemNewService items={dataServicesNewWork} onRef={setSectionRef} />
      </BoxSectionContainer>
    </>
  );
};

export default OurWorks;

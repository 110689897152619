import Carousel from 'react-material-ui-carousel';
import {Card, CardMedia, CardContent, Typography, Box} from '@mui/material';
import { dataCarouselPlank } from '../data/dataCarouselPlank';


const CarouselHomePlank = () => {
  // Função para agrupar os itens em pares
  const chunkArray = (
    array: {title: string; img: string}[],
    chunkSize: number
  ) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const groupedItems = chunkArray(dataCarouselPlank, 2); // Agrupar 2 por slide

  return (
    <Carousel
      animation='slide'
      autoPlay
      indicators
      navButtonsAlwaysVisible
      interval={3000}
    >
      {groupedItems.map((group, idx) => (
        <Box
          key={idx}
          sx={{
            padding: 2,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: {xs: 'center', md: 'space-evenly'},
            flexDirection: {xs: 'column', md: 'row'},
            height: '100%',
          }}
        >
          {group.map((item, idx) => (
            <Box key={idx}>
              <Card
                sx={{
                  mb: {xs: 2, md: '0'},
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: '220px',
                  border: '1px solid #ccc',
                  p: 2,
                  maxWidth: {
                    xs: '100%',
                    md: '250px',
                  },
                }}
              >
                <CardMedia
                  component='img'
                  image={item.img}
                  alt={item.title}
                  sx={{
                    width: {xs: 200, md: 170},
                    height: 120,
                    objectFit: 'cover',
                  }}
                />
                <CardContent>
                  <Typography sx={{maxWidth: '220px'}} component={'p'} variant='caption'>{item.title}</Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
      ))}
    </Carousel>
  );
};

export default CarouselHomePlank;

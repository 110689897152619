import imgNewService1 from '../assets/cards-our-work/image1.png';
import imgNewService2 from '../assets/cards-our-work/image2.png';
import imgNewService3 from '../assets/cards-our-work/image3.png';
import imgNewService4 from '../assets/cards-our-work/image4.png';
import imgNewService5 from '../assets/cards-our-work/image5.png';

export const dataServicesNewWork = [
  {
    imgSrc: imgNewService1,
    title: 'Hardwood Flooring',
    description:
      'For those looking for Durability: Probably the biggest benefit of wood is its unique and beautiful appearance. Hardwood is one of the oldest flooring materials and will never go out of style. Hardwood',
  },
  {
    imgSrc: imgNewService2,
    title: 'Vinyll Flooring',
    description:
      'Vinyl flooring is a durable and versatile flooring option made from synthetic materials like PVC, popular in both homes and commercial spaces. It combines style, durability, and easy maintenance, making it an affordable choice for various projects. There are two main types: Luxury Vinyl Planks (LVP), which mimic materials like wood or stone and offer easy installation, and Sheet Vinyl, ideal for covering large areas seamlessly.ams.',
  },
  {
    imgSrc: imgNewService3,
    title: 'Laminate Flooring',
    description:
      'is a cost-effective option that mimics the look of natural materials like hardwood or stone. It’s made of multiple layers, including a durable wear layer, a decorative layer for realistic appearance, and a core of high-density fiberboard for strength. Resistant to scratches and easy to maintain, it’s a practical and stylish choice for any space.',
  },
  {
    imgSrc: imgNewService4,
    title: 'Flooring Supplies',
    description:
      'include essential materials and tools for installing and maintaining floors. Common supplies are underlayment for cushioning and soundproofing, adhesives to secure flooring, trim and moldings for finishing edges, and basic installation tools like cutters and hammers. These supplies ensure proper flooring installation and durability.',
  },
  {
    imgSrc: imgNewService5,
    title: 'Carpet',
    description:
      'Carpets are a popular flooring choice, offering warmth and comfort. Made from various fibers like nylon, polyester, and wool, they come in a wide range of styles and colors. Key benefits include insulation against cold and noise, safety by providing traction, and easy maintenance through regular vacuuming. Carpets are ideal for residential spaces, especially in bedrooms and living areas, where comfort is a priority.',
  },
];

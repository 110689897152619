import React, {useState} from 'react';
import {TextField, InputAdornment, IconButton, Box, Paper} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SearchResultsList from './SearchResultsList'; // Importa o componente da lista
import { dataSearch } from '../data/dataSearch';

function SearchComponent() {


  const [searchTerm, setSearchTerm] = useState('');
  const [allItems] = useState(dataSearch); // Lista de itens com nomes e caminhos
  const [filteredItems, setFilteredItems] = useState<typeof allItems>([]);

  // Função para lidar com a mudança de valor do input de pesquisa
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    // Filtra os itens com base no termo de pesquisa
    if (value.trim()) {
      const filtered = allItems.filter((item) =>
        item.name.toLowerCase().includes(value)
      );
      setFilteredItems(filtered);
    } else {
      setFilteredItems([]);
    }
  };

  return (
    <Box className='main' sx={{position: 'relative', width: '300px'}}>
      {' '}
      {/* Container com posição relativa */}
      <Box className='search'>
        <TextField
          sx={{height: '50px', width: '100%'}}
          id='outlined-size-small'
          variant='outlined'
          label='Search for flooring, Hardwood, Vinyl ...'
          value={searchTerm}
          onChange={handleSearch}
          size='small'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {/* Exibe a lista suspensa apenas se houver um termo de pesquisa */}
      {searchTerm && filteredItems.length > 0 && (
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            top: '60px', // Posição logo abaixo do campo de texto
            width: '100%',
            maxHeight: '200px',
            overflowY: 'auto', // Adiciona scroll se a lista for muito longa
            zIndex: 100000000,
          }}
        >
          <SearchResultsList items={filteredItems} />
        </Paper>
      )}
    </Box>
  );
}

export default SearchComponent;

import imgNewService1 from '../assets/cards-new-service/1.png';
import imgNewService2 from '../assets/cards-new-service/2.png';
import imgNewService3 from '../assets/cards-new-service/3.png';

export const dataNewserviceHome = [
  {
    imgSrc: imgNewService1,
    title: 'Hardwood Flooring Installation:',
    description:
      'We provide expert installation of premium hardwood floors, ensuring a seamless fit and finish that enhances the beauty of your home or business. Our attention to detail guarantees a flawless result that adds value and style to your space.',
  },
  {
    imgSrc: imgNewService2,
    title: 'Floor Refinishing and Restoration:',
    description:
      'Our team specializes in bringing old, worn floors back to life. Using advanced refinishing techniques, we restore the natural beauty of your hardwood floors, removing scratches, dents, and signs of wear to make them look like new.',
  },
  {
    imgSrc: imgNewService3,
    title: 'Floor Repairs:',
    description:
      'From minor damage to more extensive issues, we offer professional repair services that extend the life of your floors. Our team efficiently addresses any problems, ensuring your floors remain both functional and aesthetically pleasing.',
  },
];
